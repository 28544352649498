// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

$custom-typography: mat-typography-config(
  $font-family: 'Varela Round',
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
@import 'palettes';

$primary: mat.define-palette($primary);
$accent: mat.define-palette($accent);
$warn: mat.define-palette($warn);

$gray: #a0a0a0;
$background-menu-light: #404040;
$background-menu-dark: #2e2e2d;
$primary-500: #4f8891;
$red-error: #ff6565;
