@use '@angular/material' as mat;
@import 'theming';
@import 'variables';

/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'Varela Round';
  src: url('/assets/fonts/VarelaRound-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

html,
body {
  height: 100%;
  background-color: #e8e8e8;
  margin: 0;
  font-family: 'Varela Round', sans-serif !important;
}

.p-component {
  font-family: 'Varela Round', sans-serif;
}

.mat-drawer-content,
.mat-drawer-container {
  height: 100%;
}

.header {
  font-size: 15px;
  font-weight: bold;
  color: mat.get-color-from-palette($primary);
}

.thin-form-field {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  .mat-form-field-infix {
    border-top: 0;
    padding-bottom: 0.5em !important;
  }
  .mat-select-arrow-wrapper {
    padding-top: 5px;
  }
  &.mat-form-field-appearance-outline .mat-form-field-outline {
    // background: white;
    border-radius: 5px;
  }
  &.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
    // background: #e1e1e1;
    border-radius: 5px;
  }
}

.mat-chip-list-wrapper {
  margin: 0 !important;
}

.mat-dialog-content {
  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }
}

// Custom button styles

.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled,
.mat-flat-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-primary.mat-button-disabled,
.mat-fab.mat-primary.mat-button-disabled,
.mat-mini-fab.mat-primary.mat-button-disabled {
  background: mat.get-color-from-palette($primary, 200);
  color: white;
}

.mat-flat-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-accent.mat-button-disabled,
.mat-fab.mat-accent.mat-button-disabled,
.mat-mini-fab.mat-accent.mat-button-disabled {
  background: mat.get-color-from-palette($accent, 200);
  color: white;
}

.mat-flat-button.mat-warn.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled,
.mat-fab.mat-warn.mat-button-disabled,
.mat-mini-fab.mat-warn.mat-button-disabled {
  background: mat.get-color-from-palette($warn, 200);
  color: white;
}

.p-fileupload-choose:not(.p-disabled):hover {
  background: mat.get-color-from-palette($primary, 200) !important;
  color: white !important;
}

// para los estilos de los botones de los dialog
.form-buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;

  button {
    border: none;
    height: 2.7rem !important;
    width: auto !important;
    padding: 1rem 2rem !important;
    color: white !important;
    margin-top: 7px;
    display: flex;
    background: var(--primary-500) !important;
    border-radius: 7px;
    font-weight: 900;
    font-family: 'Varela Round', sans-serif;
    font-size: 1rem;
    align-content: center;
    justify-content: center;
  }

  button.cancel-btn {
    background-color: $background-menu-light !important;
  }

  button.cancel-btn:hover {
    background-color: $background-menu-light !important;
    opacity: 0.8;
  }

  button:hover {
    cursor: pointer;
    background: var(--primary-500) !important;
    opacity: 0.9;
    color: white !important;
  }

  button:disabled {
    opacity: 0.5;
  }

  .btn button {
    padding: 1rem 2rem !important;
    height: auto !important;
  }
}

form label {
  display: block;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
}

h1.title {
  text-align: center;
  width: 100%;
  color: $primary-500;
  font-weight: bold;
  font-size: 1.4em;
}

.no-header .p-dialog {
  border-radius: 15px;

  .p-dialog-content {
    height: 100%;
    padding: 1rem 1.5rem 2rem 1.5rem !important;
    border-radius: 15px;
    app-map-edit {
      display: inline-block;
      height: auto;
      padding: 0 2rem;
    }

    app-map-create {
      display: inline-block;
      height: auto;
      padding: 0 2rem;
    }
  }
}

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background: #d2d2d2;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #a4a4a4;
  border-radius: 4px;
}
